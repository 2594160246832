import React, { Component } from "react";


import Layout from "../components/layout"
import Figure from "../components/others/figure"
import Certificado from "../components/others/certificado"
import fondo1 from "../imagenes/inicio-v3_1.jpg"
import fondo2 from "../imagenes/home-talento2.jpg"
import fondo3 from "../imagenes/mapa4.png"

import { StaticQuery, graphql } from "gatsby"

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import {
   FacebookShareButton,
   TwitterShareButton,
   LinkedinShareButton
} from "react-share";
import ReactMarkdown from 'react-markdown'

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Moment from "moment";
import es from "moment/locale/es";

Moment.locale("es", es);


const objectGraphql = graphql`
{
  allStrapiHome(filter: {locale: {eq: "es-PE"}}) {
    edges {
      node {
        id
        Cantidad_noticias
        Frases {
          id
          descripcion
          titulo
        }
        centinela {
          descripcion
          id
          titulo
        }
        productos_y_servicios {
          descripcion
          id
          imagen {
            url
          }
          titulo
        }
        gestion_de_calidad {
         descripcion
         id
         titulo
         imagen {
           url
         }
       }
       btn_gestion_calidad {
         id
         enlace
         titulo
       }
       titulo_gestion_calidad {
         titulo
       }
       Infraestructura {
         titulo
         url
         id
         descripcion
         fondo {
           url
         }
         icono {
           url
         }
       }
       cetificado {
         id
         logo {
           url
         }
         single_img {
           img {
             url
           }
         }
         titulo
       }
       talento_humano {
         descripcion
         id
         titulo
       }
       sedes {
         titulo
         telefono
         direccion
         correo
         id
       }
      }
    }
  }
  allStrapiNoticias(filter: {locale: {eq: "es-PE"}}) {
   edges {
     node {
       id
      titulo
       
      Tags {
        nombre
        id
      }
      Categorys {
         nombre
       }
      fecha
      min_descripcion
      descripcion
      imagen {
        url
      }
     }
   }
  }
}
`

function SampleNextArrow(props) {
   const { className, style, onClick } = props;
   return (
      <div
         className={className}
         style={{ ...style, display: "block" }}
         onClick={onClick}
      >
         <i className="fa fa-chevron-right" />
      </div>
   );
}

function SamplePrevArrow(props) {
   const { className, style, onClick } = props;
   return (
      <div
         className={className}
         style={{ ...style, display: "block" }}
         onClick={onClick}
      >
         <i className="fa fa-chevron-left" />
      </div>
   );
}


// markup
class Home extends Component {
   items = (data) => {
      console.log(data);

   }
   news = (data) => {
      let array = data.allStrapiNoticias.edges;
      let sortedArray = array.sort((a, b) => new Moment(a.node.fecha).format('YYYYMMDD') - new Moment(b.node.fecha).format('YYYYMMDD'))
      let aNuevo = (sortedArray.slice(sortedArray.length - (data.allStrapiHome.edges[0].node.Cantidad_noticias))).reverse()


      return aNuevo.map((item) => {
         return (
            <div key={item.node.id} className="item">
               <div className="row">
                  <div className="col-3 col-md-2 col-lg-1">
                     <div className="fecha">
                        <p>
                           {Moment(item.node.fecha).format(
                              "D"
                           )}                      </p>
                        <p>
                           {Moment(item.node.fecha).format(
                              "MMMM"
                           )}                     </p>
                     </div>
                  </div>
                  <div className="col-9 col-md-5 col-lg-5">
                     <h2>
                        <a href={`/es/noticias/${(item.node.titulo).replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}`} >
                           {item.node.titulo}                     </a>
                     </h2>
                     <p className="tags">
                        <i className="fa fa-tag"  ></i>
                        {item.node.Tags.map((item, index) => {

                           return (
                              <a key={index} href={`/es/tag/${item.nombre.replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}`}>  {item.nombre}  </a>
                           )
                        })}

                     </p>

                     <div className='lh-p' >
                        <ReactMarkdown>{item.node.min_descripcion}</ReactMarkdown>
                     </div>
                     <div className="row">
                        <div className="col-lg-5">
                           <p>
                              <a href={`/es/noticias/${(item.node.titulo).replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}`} className="btn btn-secondary">
                                 <i className="fa fa-file-o"  ></i>  Seguir leyendo                                                  </a>
                           </p>
                        </div>
                        <div className="col-lg-7">
                           <p style={{ display: 'flex' }}>

                              <FacebookShareButton
                                 title={item.node.titulo}

                                 url={this.props.props.location.host + "/es/noticias/" + (item.node.titulo).replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}
                              >
                                 <div className='redes fac' >
                                    <i className="fa fa-facebook"  ></i>
                                 </div>
                              </FacebookShareButton>



                              <TwitterShareButton
                                 title={item.node.titulo}
                                 url={this.props.props.location.host + "/es/noticias/" + (item.node.titulo).replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}
                              >
                                 <div className='redes twi' >
                                    <i className="fa fa-twitter"  ></i>
                                 </div>
                              </TwitterShareButton>



                              <LinkedinShareButton className='redes lin'
                                 title={item.node.titulo}
                                 summary={item.node.min_descripcion}
                                 url={this.props.props.location.host + "/es/noticias/" + (item.node.titulo).replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}
                              >
                                 <div className='redes lin' >
                                    <i className="fa fa-linkedin"  ></i>
                                 </div>
                              </LinkedinShareButton>


                           </p>
                        </div>
                     </div>
                  </div>
                  <div className="col-md-5 col-lg-6">
                     <p>
                        <a href={`/es/noticias/${(item.node.titulo).replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}`} >
                           <img src={item.node.imagen[0].url} alt={item.node.imagen[0].url} className="img-fluid" />
                        </a>
                     </p>
                  </div>
               </div>
            </div>
         )
      })
   }

   render() {

      var settings = {
         dots: false,
         arrows: false,
         infinite: true,
         slidesToShow: 3,
         slidesToScroll: 1,
         autoplay: true,
         speed: 100,
         autoplaySpeed: 4500,
         pauseOnHover: true,
         // cssEase: "linear",


         responsive: [

            {
               breakpoint: 600,
               settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
               }
            },
            {
               breakpoint: 480,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
               }
            }
         ],
      };
      var settings2 = {
         dots: false,
         arrows: true,
         infinite: true,
         slidesToShow: 4,
         slidesToScroll: 1,
         speed: 100,
         autoplaySpeed: 5000,
         pauseOnHover: true,
         nextArrow: <SampleNextArrow />,
         prevArrow: <SamplePrevArrow />,
         responsive: [

            {
               breakpoint: 1200,
               settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  initialSlide: 3
               }
            },
            {
               breakpoint: 600,
               settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
               }
            },
            {
               breakpoint: 480,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
               }
            }
         ],
      };

      return (
         <StaticQuery
            query={objectGraphql}
            render={data => (
               <React.Fragment>
                  <Layout nameTab='Inicio' init='init' props={this.props.props}>
                     <section className="inicio"
                        style={{ background: "url(" + fondo1 + ") top center #000", backgroundSize: "100% auto", backgroundRepeat: "no-repeat" }}>
                        <div className="container frases" >

                           <div className="row">

                              <ScrollAnimation className="col-md-8 offset-md-2 text-center  " animateIn="fadeInUp" >
                                 <AliceCarousel
                                    mouseTracking
                                    infinite
                                    autoPlay
                                    autoPlayStrategy="none"
                                    autoPlayInterval={3500}
                                    animationDuration={500}
                                    disableButtonsControls>
                                    {
                                       data.allStrapiHome.edges[0].node.Frases.map((item) => {
                                          return (
                                             <div key={item.id} data-value={item.id} className="item">
                                                <h3>{item.titulo}</h3>
                                                <h2>{item.descripcion} </h2>
                                             </div>
                                          )
                                       })
                                    }

                                 </AliceCarousel>
                              </ScrollAnimation>
                           </div>
                        </div >

                        <div className="container centinela">
                           <div className="row margin-b-100">
                              <ScrollAnimation className="col-md-8 offset-md-2 titulo-center-b text-center" animateIn="fadeInUp">
                                 <h2>{data.allStrapiHome.edges[0].node.centinela.titulo}</h2>
                                 <h4 className="color-b">
                                    {data.allStrapiHome.edges[0].node.centinela.descripcion}
                                 </h4>
                              </ScrollAnimation>
                           </div>
                        </div>

                        <div className="container">
                           <div className="row">
                              <ScrollAnimation className="col-md-12 titulo-center-b text-center wow " animateIn="fadeInUp">
                                 <h2>PRODUCTOS Y SERVICIOS</h2>
                              </ScrollAnimation>
                           </div>
                           <div className="row">
                              <div className="col-md-12 wow fadeInUp" id="productos">
                                 <ScrollAnimation style={{ margin: '-20px' }} animateIn="fadeInUp">
                                    <Slider  {...settings}
                                    >
                                       {

                                          (data.allStrapiHome.edges[0].node.productos_y_servicios).concat(data.allStrapiHome.edges[0].node.productos_y_servicios).map((item) => {
                                             return (
                                                <div style={{ margin: '20px' }} key={item.id} data-value={item.id} className="item text-center item__slick">

                                                   <img src={item.imagen[0].url} alt={item.imagen[0].url} className="img-fluid" />

                                                   <h5 style={{ color: '#dda117', marginTop: '25px' }}>
                                                      {item.titulo}
                                                   </h5>
                                                   <p></p>
                                                   <p style={{ width: '80%', margin: "auto", lineHeight: 1.6 }}>{item.descripcion}</p>
                                                   <p></p>

                                                </div>

                                             )
                                          })
                                       }

                                    </Slider>


                                 </ScrollAnimation>
                              </div>
                           </div>
                        </div>
                     </section>

                     <section className="padding-section">
                        <div className="container">
                           <div className="row">
                              <div className="col-md-12 titulo-center text-center">
                                 <h2>{data.allStrapiHome.edges[0].node.titulo_gestion_calidad.titulo}</h2>               </div>
                              <div className="col-md-12 wow fadeInUp">
                                 <div id="gestion" >
                                    <Slider
                                       {...settings2}
                                    >
                                       {
                                          (data.allStrapiHome.edges[0].node.gestion_de_calidad).concat(data.allStrapiHome.edges[0].node.gestion_de_calidad).map((item) => {
                                             return (
                                                <div style={{ marginRight: '20px' }} key={item.id} data-value={item.id} className="item text-center item__slick">
                                                   <img src={item.imagen[0].url} alt={item.imagen[0].url} className="img-radius" />
                                                   <h4 style={{ marginTop: '15px' }} >{item.titulo}
                                                   </h4>
                                                   <p></p><p style={{ lineHeight: 1.6 }} >{item.descripcion}</p>
                                                   <p></p>

                                                </div>
                                             )
                                          })
                                       }

                                    </Slider>
                                 </div></div>
                           </div>

                           <div className="row" style={{ marginTop: '90px' }}>
                              <div className="col-md-12 titulo-center text-center">
                                 <p><a className="btn btn-primary" href={data.allStrapiHome.edges[0].node.btn_gestion_calidad.enlace}><i className="fa fa-check"></i>  {data.allStrapiHome.edges[0].node.btn_gestion_calidad.titulo}</a></p>
                              </div>
                           </div>

                        </div>
                     </section>
                     <section>

                        <div className="container">
                           <div className="row">
                              <div className="col-md-12 titulo-center text-center">
                                 <h2>Infraestructura</h2>               </div>
                           </div>
                        </div>
                        <Figure figure={data.allStrapiHome.edges[0].node.Infraestructura} text=' Seguir leyendo  ' />
                        <ScrollAnimation animateIn="fadeInUp" ><Certificado certificado={data.allStrapiHome.edges[0].node.cetificado} />
                        </ScrollAnimation >
                     </section>

                     <section className="talento"
                        style={{ background: "url(" + fondo2 + ") no-repeat top center", backgroundSize: "cover", backgroundAttachment: "fixed" }}
                     >
                        <div className="container content-slide">
                           <div className="row">
                              <div className="col-10 col-lg-8 offset-1 offset-lg-2 fondo-tra">
                                 <div className="row text-center">
                                    <ScrollAnimation className="col-10 offset-1 titulo-center wow " animateIn="fadeIn">
                                       <h2>{data.allStrapiHome.edges[0].node.talento_humano.titulo}</h2>
                                       <p>{data.allStrapiHome.edges[0].node.talento_humano.descripcion}</p>

                                       <p><a className="btn btn-primary" href="/es/talento-humano"><i className="fa fa-file-o"></i> Seguir leyendo</a></p>
                                    </ScrollAnimation>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </section>
                     <section className="sedes">
                        <div className="container-fluid">
                           <div className="row">
                              <div className="col-sm-5 col-lg-3 offset-lg-2 padding-section">
                                 <h3>Sedes</h3>
                                 <h4> Pesquera Centinela</h4>
                                 <div className="row">
                                    <div className="col-sm-12">
                                       <div id="sedes" >
                                          <AliceCarousel mouseTracking

                                             controlsStrategy="alternate"
                                             infinite
                                             autoPlayStrategy="none"
                                             autoPlayInterval={7500}
                                             animationDuration={500}
                                             keyboardNavigation
                                             disableDotsControls
                                          >
                                             {
                                                data.allStrapiHome.edges[0].node.sedes.map((item) => {
                                                   return (
                                                      <div key={item.id} className="item">
                                                         <h5>{item.titulo}</h5>
                                                         <div style={{ lineHeight: 1.6 }} className="mb-0-p"> <ReactMarkdown>
                                                            {item.direccion}
                                                         </ReactMarkdown></div>
                                                         <br />
                                                         <p> {item.telefono}</p>
                                                         <p>{item.correo}</p>
                                                      </div>
                                                   )
                                                })
                                             }
                                          </AliceCarousel>
                                       </div>
                                    </div>
                                 </div>

                              </div>
                              <ScrollAnimation animateIn="fadeIn" className="col-sm-7 col-lg-7 wow padding-0"

                                 style={{
                                    background: "rgba(0, 0, 0, 0) url(" + fondo3 + ") repeat scroll left center",
                                    visibility: "visible"
                                 }}>
                              </ScrollAnimation>
                           </div>
                        </div>
                     </section>
                     <section className="padding-section">
                        <div className="container">
                           <div className="row">
                              <div className="col-md-12 titulo-center text-center">
                                 <h2>
                                    Noticias Centinela
                                 </h2>
                              </div>
                           </div>

                           <div className="row">
                              <div className="col-md-12">

                                 <div id="notas">
                                    <AliceCarousel mouseTracking

                                       controlsStrategy="alternate"
                                       infinite
                                       autoPlay
                                       autoPlayStrategy="none"
                                       autoPlayInterval={6000}
                                       animationDuration={1000}
                                       keyboardNavigation
                                       disableButtonsControls
                                       animationType="fadeout"

                                    >
                                       {

                                          this.news(data)
                                       }
                                    </AliceCarousel>
                                 </div>
                              </div>
                           </div>

                           <div className="row">
                              <div className="col-md-12 titulo-center text-center">
                                 <p>
                                    <a href="/es/noticias" className="btn btn-primary">
                                       <i className="fa fa-file-o"
                                       ></i> Ver más noticias
                                    </a>
                                 </p>
                              </div>
                           </div>
                        </div>
                     </section>
                  </Layout>
               </ React.Fragment>
            )}
         />
      )
   }
}

export default Home
